import React, { useEffect, useState } from 'react'

export type DimensionsType = {width: number, height: number};

export default function useWindowDimensions() {
  const hasWindow = typeof window !== 'undefined';

  const getWindowDimensions = (): DimensionsType => {
    const width = hasWindow ? window.innerWidth : 0;
    const height = hasWindow ? window.innerHeight : 0;
    return {
      width,
      height
    };
  }

  const [windowDimensions, setWindowDimensions] = useState<DimensionsType>(getWindowDimensions());

  useEffect(() => {
    if (hasWindow) {
      const handleResize = () => setWindowDimensions(getWindowDimensions());

      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      }
    }
  }, [hasWindow]);

  return windowDimensions;
}