import tinycolor from 'tinycolor2';

const isValid = (value: string): boolean => tinycolor(value).isValid();

const isDark = (value: string): boolean => {
  if (!value) return false;
  //return tinycolor(color).isDark();
  const color = tinycolor(value).toRgb();
  return (((color.r * 0.299) + (color.g * 0.587) + (color.b * 0.114)) / 255) < 0.5;
}

const isNotWhite = (color: string): boolean => {
  return color.toLowerCase() !== '#fff' && color.toLowerCase() !== '#ffffff' && color.toLowerCase() !== '#ffff';
}

const hex2rgba = (hex: string, alpha = 100): string => {
  /* const [r, g, b] = hex.match(/\w\w/g).map(x => parseInt(x, 16));
  return `rgba(${r},${g},${b},${alpha})`; */
  return tinycolor(hex).setAlpha(alpha).toRgbString();
};

const changeAlpha = (color: string, alpha = 100): string => {
  return tinycolor(color).setAlpha(alpha).toRgbString();
};

const brighter = (hex: string, value = 50): string => {
  return tinycolor(hex).brighten(value).toRgbString();
};

const darker = (hex: string, value = 50): string => {
  return tinycolor(hex).darken(value).toRgbString();
};

export default {
  isValid,
  isDark,
  isNotWhite,
  hex2rgba,
  changeAlpha,
  brighter,
  darker,
}